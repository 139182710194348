































import Base from "@/views/Base";
import {Component, Prop} from "vue-property-decorator";
import {DownloadListItem} from "@/models/Presentation";
import {formatFileSize} from "@/utils/filters";
import {MimeTypes} from "@/utils/i18n";
import axios from 'axios';

@Component
export default class PresentationDownloadRow extends Base {
  @Prop({required: true}) item!: DownloadListItem;

  get fileSize(): string {
    return formatFileSize(this.item.fileSize);
  }

  get fileType(): string {
    return this.$t(MimeTypes.getI18nKeyForMimeType(this.item.fileType)) as string;
  }

  get thumbnailUrl() {
    if(this.item.hasThumbnail){
      return axios.defaults.baseURL + '/presentation/' + this.item.presentationId + '/download/' + this.item.uuid + '/thumbnail';
    } else if(this.item.thumbnailUrl){
      return this.item.thumbnailUrl;
    } else {
      return "https://via.placeholder.com/300";
    }
  }
}
