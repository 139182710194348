import { render, staticRenderFns } from "./ElementTable.vue?vue&type=template&id=37fb705a&scoped=true&"
import script from "./ElementTable.vue?vue&type=script&lang=ts&"
export * from "./ElementTable.vue?vue&type=script&lang=ts&"
import style0 from "./ElementTable.vue?vue&type=style&index=0&id=37fb705a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37fb705a",
  null
  
)

export default component.exports