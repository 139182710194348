



















































import Base from "@/views/Base";
import {Component, Prop, Watch} from "vue-property-decorator";
import ElementCard from "@/views/presentation/components/ElementCard.vue";
import {DownloadListItem} from "@/models/Presentation";
import draggable from "vuedraggable";
import DownloadRow from "@/views/presentation/components/DownloadRow.vue";

@Component({
  components: {DownloadRow, ElementCard, draggable}
})
export default class PresentationDownloadTable extends Base {
  @Prop({required: true}) value!: DownloadListItem[];
  @Prop() selectedElement!: DownloadListItem | null;
  @Prop({type: Boolean}) allSelected!: boolean;

  items: DownloadListItem[] = [];

  created(): void {
    this.items = this.value;
  }

  @Watch('value', {immediate: true, deep: true})
  onItemsChanged(newItems: DownloadListItem[]) {
    this.items = [...newItems];
  }

  updateItems() {
    this.$emit('input', this.items);
  }
}
