





















import Base from "@/views/Base";
import {Component, Prop} from "vue-property-decorator";
import AddSurveyModal from "@/views/presentation/components/AddSurveyModal.vue";
import { ElementListItem, SurveyElement } from '@/models/Presentation';

@Component({
  components: {AddSurveyModal}
})
export default class SurveyElementForm extends Base {
  @Prop({required: true}) count!: number;

  item: SurveyElement | null = null;

  editSurvey(item: ElementListItem){
    this.item = item.entity as SurveyElement;
    this.$bvModal.show('add-survey-modal');
  }

  modalHidden(){
    this.item = null;
  }

}
