






















































































































































































































































































































































































































































































import { SelectOption } from '@/models/common';
import {
  Registration,
  RegistrationDate,
  RegistrationField,
  RegistrationUser,
  RegistrationUserWithData
} from '@/models/Registration';
import ContactsService from '@/service/ContactsService';
import RegistrationService from '@/service/RegistrationService';
import { readXLSX } from '@/utils/excel';
import { Component, Prop } from 'vue-property-decorator';
import Base from '../../Base';
import ImportItem from './ImportModalItem.vue';

@Component({
  components: {
    ImportItem
  }
})
export default class RegistrationImportModal extends Base {
  @Prop() registration!: Registration;
  @Prop() date!: RegistrationDate;
  @Prop() users!: RegistrationUserWithData[];

  verified = false;
  loading = false;
  draggedOver = false;
  parseLoading = false;

  options: SelectOption[] = [];

  encodingOptions = [
    { text: 'ISO-8859-1', value: 'ISO-8859-1' },
    { text: 'UTF-8', value: 'UTF-8' },
    { text: 'ANSI', value: 'ansi_x3.4-1968' }
  ];
  encoding = 'UTF-8';

  parsedData: RegistrationUser[] | null = null;
  fieldNames: any = {
    firstName: null,
    lastName: null,
    phone: null,
    email: null,

    company: null,

    address: null,
    city: null,
    partnerId: null,
    vvId: null,
    comment: null,
    salutation: null,
    custom: {}
  };

  data: string[][] = [];
  validData: RegistrationUser[] = [];
  dupletteData: RegistrationUser[] = [];
  invalidData: RegistrationUser[] = [];
  deletedData: RegistrationUser[] = [];

  showValid = false;
  importView: 'valid' | 'invalid' | 'deleted' | 'duplette' = 'valid';

  get customFieldKeys(): string[] {
    return Object.keys(this.fieldNames.custom);
  }

  mounted(): void {
    if (!this.registration.fields) return;
    this.registration.fields
      .sort((a, b) => {
        if (a.type === 'CHECKBOX' && b.type !== 'CHECKBOX') return 1;
        if (a.type !== 'CHECKBOX' && b.type === 'CHECKBOX') return -1;
        if (a.type === 'CHECKBOX' && b.type === 'CHECKBOX') return a.idx - b.idx;
        return 0;
      })
      .forEach(field => {
        this.fieldNames.custom[field.title] = {
          value: null,
          field
        };
      });
  }

  updateData(): void {
    const numbers: (string | undefined)[] = this.users.map(u => u.phone);
    const emails: string[] = this.users.map(u => u.email);
    if (this.parsedData) {
      for (let i = 0; i < this.parsedData.length; i++) {
        const item = this.parsedData[i];
        if (
          (item.phone && numbers.includes(item.phone)) ||
          (emails.includes(item.email) && item.email)
        ) {
          this.dupletteData.push(item);
        } else {
          console.log(item);
          numbers.push(item.phone);
          emails.push(item.email);
          if (item.valid) this.validData.push(item);
          if (!item.valid) this.invalidData.push(item);
        }
      }
    }
    this.importView = this.invalidData.length > 0 ? 'invalid' : 'valid';
  }

  fileUploaded(e: Event): void {
    this.parseLoading = true;
    const file = ((e.target as HTMLInputElement).files as FileList)[0];
    if (file.type.includes('csv')) {
      const self = this as any;
      self.$papa.parse(file, {
        encoding: this.encoding,
        delimitersToGuess: [',', ';'],
        complete: (results: { data: string[][] }) => {
          this.draggedOver = false;
          const header: string[] = results.data[0];

          // Map possible columns from csv-sheet and add '-' option to select nothing
          this.options =
            header.length === 1
              ? header[0].split(';').map((v, i) => ({ value: i, text: v }))
              : header.map((v, i) => ({ value: i, text: v }));
          this.setFieldNames(this.options);
          this.options.unshift({ value: null, text: ' ' });

          if (!this.checkHeaderForNumber(header)) results.data.shift();
          this.data = results.data.map((entry) =>
            entry.length === 1 ? entry[0].split(';') : entry
          );
          this.data = this.data.filter((entry) => entry.length > 1);
          this.parsedData = [];
          this.parseLoading = false;
        }
      });
    } else if (file.type.includes('sheet')) {
      readXLSX(file).then((strings) => {
        if (strings.length > 0) {
          this.draggedOver = false;

          // Map possible columns from xlsx-sheet and add '-' option to select nothing
          this.options = strings[0].map((v, i) => ({ value: i, text: v }));
          this.setFieldNames(this.options);
          this.options.unshift({ value: -1, text: ' ' });
          this.data = strings.slice(1);
          this.parsedData = [];
        } else {
          this.toast('Überprüfen Sie die Excel-Datei. Es darf nur ein Arbeitsblatt vorliegen.', 'danger');
        }
      }).catch(e => {
        console.error(e);
        this.toast(this.t('adressbook.excelFileError'), 'danger', 10000);
      }).finally(() => this.parseLoading = false);
    }
  }

  setFieldNames(header: SelectOption[]): void {
    const firstnameIndex = header.findIndex(v => v.text.toLowerCase().includes('vorname'));
    this.fieldNames.firstName = firstnameIndex >= 0 ? firstnameIndex : null;

    const lastNameIndex = header.findIndex(v => v.text.toLowerCase().includes('nachname'));
    this.fieldNames.lastName = lastNameIndex >= 0 ? lastNameIndex : null;

    // requirement by Torsten to prioritize Telefon1 (geschäftlich) as match for phone numbers to support
    // csv files from the old system. Telefon1 (geschäftlich) is a required field in the old system.
    let phoneIndex = header.findIndex(v => v.text.toLowerCase().includes('telefon1 (gesch'));
    if (phoneIndex === -1) phoneIndex = header.findIndex(v => v.text.toLowerCase().includes('nummer') || v.text.toLowerCase().includes('telefon'));
    this.fieldNames.phone = phoneIndex >= 0 ? phoneIndex : null;

    const emailIndex = header.findIndex(v => v.text.toLowerCase().includes('mail'));
    this.fieldNames.email = emailIndex >= 0 ? emailIndex : null;

    const companyIndex = header.findIndex(v => v.text.toLowerCase().includes('firma'));
    this.fieldNames.company = companyIndex >= 0 ? companyIndex : null;

    const addressIndex = header.findIndex(v => v.text.toLowerCase().includes('straße') || v.text.toLowerCase().includes('strasse'));
    this.fieldNames.address = addressIndex >= 0 ? addressIndex : null;

    const cityIndex = header.findIndex(v => v.text.toLowerCase().includes('plz') || v.text.toLowerCase().includes('postleitzahl'));
    this.fieldNames.city = cityIndex >= 0 ? cityIndex : null;

    const partnerIdIndex = header.findIndex(v => v.text.toLowerCase().includes('partner'));
    this.fieldNames.partnerId = partnerIdIndex >= 0 ? partnerIdIndex : null;

    const vvIdIndex = header.findIndex(v => v.text.toLocaleLowerCase().includes('vv-id'));
    this.fieldNames.vvId = vvIdIndex >= 0 ? vvIdIndex : null;

    const commentIndex = header.findIndex(v => v.text.toLocaleLowerCase().includes('bemerkung'));
    this.fieldNames.comment = commentIndex >= 0 ? commentIndex : null;

    const salutationIndex = header.findIndex(v => v.text.toLocaleLowerCase().includes('titel') || v.text.toLocaleLowerCase().includes('anrede'));
    this.fieldNames.salutation = salutationIndex >= 0 ? salutationIndex : null;

    Object.entries(this.fieldNames.custom).forEach(entry => {
      const index = header.findIndex(v => v.text === entry[0]);
      this.fieldNames.custom[entry[0]].value = index >= 0 ? index : null;
    });
  }

  checkHeaderForNumber(header: string[]): boolean {
    let result = false;
    for (let col of header) {
      if (col.replace(/\s/g, '').match(/\d{6,}/gm)) {
        result = true;
        break;
      }
    }
    return result;
  }

  reset(close?: () => void): void {
    this.data = [];
    this.parsedData = null;
    this.invalidData = [];
    this.validData = [];
    this.deletedData = [];
    this.verified = false;
    this.dupletteData = [];
    if (close) close();
  }

  deleteContact(invalidIndex: number): void {
    if (this.parsedData) {
      const deletedItem = this.invalidData.splice(invalidIndex, 1);
      this.deletedData = [deletedItem[0], ...this.deletedData];
    }
  }

  submitContact(invalidIndex: number, contactValid?: boolean): void {
    if (this.parsedData && contactValid) {
      const validItem = this.invalidData.splice(invalidIndex, 1);
      this.validData.unshift(validItem[0]);
    }
  }

  verify(): void {
    if (this.parsedData) {
      this.parsedData = this.data.map((item: any) => ({
        id: Date.now(),
        firstname:
          this.fieldNames.firstName !== null
            ? item[this.fieldNames.firstName]
            : '',
        lastname:
          this.fieldNames.lastName !== null
            ? item[this.fieldNames.lastName]
            : '',
        phone:
          this.fieldNames.phone !== null
            ? item[this.fieldNames.phone]
              ? item[this.fieldNames.phone]
              : ''
            : '',
        email:
          this.fieldNames.email !== null
            ? item[this.fieldNames.email]
              ? item[this.fieldNames.email].text
                ? item[this.fieldNames.email].text
                : item[this.fieldNames.email]
              : ''
            : '',
        company:
          this.fieldNames.company !== null ? item[this.fieldNames.company] : '',
        dateCreated: Date.now(),
        values:
          this.customFieldKeys.length > 0
            ? this.customFieldKeys.map(key => {
              const field = this.fieldNames.custom[key].field as RegistrationField;
              if (field.type === 'CHECKBOX') {
                const value = item[this.fieldNames.custom[key].value] || false;
                return {
                  fieldId: field.id,
                  value: value === '✓' || value === 'ja' || value === 'true'
                };
              }
              const value = item[this.fieldNames.custom[key].value] || '';
              return {
                fieldId: field.id,
                value
              };
            })
            : [],
        vvId: this.fieldNames.vvId !== null ? item[this.fieldNames.vvId] : '',
        addressStreet:
          this.fieldNames.address !== null ? item[this.fieldNames.address] : '',
        addressCity:
          this.fieldNames.city !== null ? item[this.fieldNames.city] : '',
        partnerId:
          this.fieldNames.partnerId !== null
            ? item[this.fieldNames.partnerId]
            : '',
        comment:
          this.fieldNames.comment !== null ? item[this.fieldNames.comment] : '',
        salutation:
          this.fieldNames.salutation !== null
            ? this.setSalutation(item[this.fieldNames.salutation])
            : '',
        dateIds: [this.date.id],
        dateDoi: Date.now()
      })).filter(item => {
        return this.itemNotNullOrEmpty(item.email)
          || this.itemNotNullOrEmpty(item.firstname)
          || this.itemNotNullOrEmpty(item.lastname)
          || (this.itemNotNullOrEmpty(item.phone) && this.registration.phoneRequired);
      }) as RegistrationUser[];

      this.loading = true;
      const numbers = this.parsedData.map((item) => item.phone).filter(phone => phone) as string[];
      ContactsService.validatePhoneNumbers(numbers)
        .then((validatedNumbers) => {
          this.parsedData?.forEach((item) => {
            const validatedNum = validatedNumbers.find(validated=>validated.originalNumber == item.phone);
            if(validatedNum){
              this.checkItemValidity(item, validatedNum?.validNumber);
              item.phone = !validatedNum?.validNumber
                ? item.phone
                : validatedNum?.international;
            } else {
              item.valid = !this.registration.phoneRequired;
            }
          });
          this.updateData();
          this.verified = true;
          this.loading = false;
        })
        .catch(err => this.toast(err.message, 'danger'));
    }
  }

  private itemNotNullOrEmpty(s: string | undefined | null): boolean {
    return !(s === null || s === undefined || s.trim() === '');
  }

  private checkItemValidity(item: any, numberValid: boolean) {
    const nameValid = item.lastname && item.lastname !== '';
    item.valid = nameValid && numberValid;
  }

  private setSalutation(text: string): string {
    if (
      text.toLocaleLowerCase().includes('herr') ||
      text.toLocaleLowerCase().includes('mr') ||
      text.toLocaleLowerCase().includes('male') ||
      text.toLocaleLowerCase().includes('man')
    )
      return 'male';
    if (
      text.toLocaleLowerCase().includes('frau') ||
      text.toLocaleLowerCase().includes('mrs') ||
      text.toLocaleLowerCase().includes('female') ||
      text.toLocaleLowerCase().includes('woman')
    )
      return 'female';
    if (
      text.toLocaleLowerCase().includes('divers') ||
      text.toLocaleLowerCase().includes('various') ||
      text.toLocaleLowerCase().includes('miscellaneous')
    )
      return 'divers';
    return '';
  }

  truncateTooltip(text: string): string {
    return text ? (text.length >= 23 ? text : '') : '';
  }

  async importData(ok: () => void): Promise<void> {
    if (this.parsedData && this.registration.id) {
      await RegistrationService
        .addUsers(this.registration.id as number, this.validData)
        .catch(this.showNetworkError);
      this.reset(ok);
      this.$emit('updateTable');
      this.toast('Teilnehmer erfolgreich hinzugefügt!', 'success');
    }
  }
}
