














































import { Component, Prop } from 'vue-property-decorator';
import WExpandableCard from '@/components/WExpandableCard.vue';
import { SpeakerCardModel, SpeakerCardSocialLink } from '@/models/SpeakerCard';
import { SelectOption } from '@/models/common';
import Base from '@/views/Base';
import { Validations } from 'vuelidate-property-decorators';
import { required, url } from 'vuelidate/lib/validators';

@Component({
  components: { WExpandableCard }
})
export default class SocialLinks extends Base {
  @Prop() speakerCard!: SpeakerCardModel;
  @Prop({ required: false, default: false, type: Boolean }) disabled!: boolean;

  socialLink: SpeakerCardSocialLink = {
    url: '',
    title: ''
  };

  editingLink: SpeakerCardSocialLink | null = null;

  patterns: {[key: string]: RegExp} = {
    linkedin: /linkedin\.com\/(company|in)\/.+/,
    xing: /xing\.com\/(profile|pages)\/.+/,
    facebook: /facebook\.com\/.+/,
    instagram: /instagram\.com\/.+/,
    vimeo: /vimeo\.com\/.*\/?(user)?[0-9]+/,
    x: /(x\.com|twitter\.com)\/.+/,
    youtube: /(youtube\.com|youtu\.be)\/(@|watch).+/,
  }

  validateUrl(url: string): boolean{
    const pattern = this.patterns[this.socialLink.title];
    if(pattern){
      return pattern.test(url);
    }
    return true;
  }

  @Validations()
  validations = {
    socialLink: {
      url: { url, required, platform: this.validateUrl },
      title: { required }
    }
  };

  options: SelectOption[] = [
    { value: '', text: this.t('presentation.speakerProfiles.selectNetwork'), disabled: true },
    { value: 'linkedin', text: this.t('presentation.speakerProfiles.networks.linkedin') },
    { value: 'xing', text: this.t('presentation.speakerProfiles.networks.xing') },
    { value: 'facebook', text: this.t('presentation.speakerProfiles.networks.facebook') },
    { value: 'instagram', text: this.t('presentation.speakerProfiles.networks.instagram') },
    { value: 'vimeo', text: this.t('presentation.speakerProfiles.networks.vimeo') },
    { value: 'x', text: this.t('presentation.speakerProfiles.networks.x') },
    { value: 'youtube', text: this.t('presentation.speakerProfiles.networks.youtube') }
  ];

  editLink(socialLink: SpeakerCardSocialLink) {
    (this.$refs.card as any).setOpen();
    this.socialLink.url = socialLink.url;
    this.socialLink.title = socialLink.title;
    this.editingLink = socialLink;
  }

  addSocialLink() {
    this.$v.socialLink.$touch();
    if (this.$v.socialLink.$invalid) {
      return;
    }
    if (this.editingLink == null) {
      this.speakerCard.speakerProfile?.socialLinks?.push(
        { title: this.socialLink.title, url: this.socialLink.url }
      );
    } else {
      this.editingLink.url = this.socialLink.url;
      this.editingLink.title = this.socialLink.title;
      this.editingLink = null;
    }

    this.socialLink.title = '';
    this.socialLink.url = '';
    this.$v.socialLink.$reset();
  }

  cancelEditing(){
    this.$v.$reset();
    this.editingLink = null;
    this.socialLink.url = '';
    this.socialLink.title = ''
  }

  socialUrlBlur() {
    if (this.socialLink.url) {
      if (this.socialLink.url.startsWith('http://')) {
        this.socialLink.url = 'https://' + this.socialLink.url.substring(7);
      } else if (!this.socialLink.url.startsWith('https://')) {
        this.socialLink.url = 'https://' + this.socialLink.url;
      }
    }
  }
}
