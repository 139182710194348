

































































































































































































































































































































































































































































































































































import { Component, Watch } from 'vue-property-decorator';
import AuthenticationService from '@/service/AuthenticationService';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import SidebarLayout from '@/layouts/nested/SidebarLayout.vue';
import WSidebarLink from '@/components/WSidebarLink.vue';
import WCostCenter from '@/components/WCostCenter.vue';
import ProfileSection from './ProfileSection.vue';
import { Account, Permission, PermissionName } from '@/models/Account';
import { Validations } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import ContactsService from '@/service/ContactsService';
import Base from '../Base';
import WSwitch from '@/components/WSwitch.vue';
import WCountrySelect from '@/components/WCountrySelect.vue';
import AccountsService from '@/service/AccountsService';
import { SelectOption } from '@/models/common';
import LoginPasswordResetModal from '../LoginPasswordResetModal.vue';
import { validateEmail } from '@/utils/validators';
import {getLanguages} from "@/utils/languages";

/**
 * Some documented component
 *
 * @component
 */
@Component({
	components: {
		HeaderLayout,
		SidebarLayout,
		WSidebarLink,
		ProfileSection,
		WCostCenter,
		WSwitch,
		WCountrySelect,
		LoginPasswordResetModal
	}
})
export default class Profile extends Base {
	account: Account = {
		role: 'USER',
		timezone: '',
		username: '',
		dateCreated: 0,
		id: 0,
		language: 'DE',
		permissions: [],
		contact: {
			lastName: '',
			email: '',
			firstName: '',
			phone: '',
			city: '',
			country: '',
			street: '',
			zip: '',
			company: '',
			salutation: 'MR',
			title: '',
			phone2: ''
		},
		costCenter: '',
		passwordPlain: null,
		active: true,
		dateLastLogin: Date.now(),
		deactivationReason: 'NONE',
		failedLoginCounter: 0,
		participantLimit: 0,
		passwordPolicy: 'NONE',
		timeLimit: 0,
		settings: '',
		customerId: 0,
		bookingPreset: {
			dialOutCallRetryCount: 0,
			dialOutCallRetryDelay: 0
		},
    passwordExpiresIn: null
	};

	personal: { [key: string]: string } = {};
	address: { [key: string]: string } = {};
	contact: { [key: string]: string } = {};
	settings: { [key: string]: string } = {};

	languages = getLanguages(this);
	timezones!: SelectOption[];
	salutations = [
		{ text: this.t('common.mr'), value: 'MR' },
		{ text: this.t('common.mrs'), value: 'MRS' },
		{ text: this.t('common.diverse'), value: 'OTHER' }
	];

	newCostCenter = '';

	costCenters = [
		{ text: this.t('conference.noCostCenter'), value: '', undeletable: true }
	];

	retryCountOptions = [2, 3, 4, 5, 6].map((i) => ({ text: i - 1, value: i }));
	retryDelayOptions = [10, 20, 30, 40, 50, 60, 90, 120].map((i) => ({
		text: i + 's',
		value: i
	}));

	editActive = false;
	loadingPhone1 = false;
	loadingPhone2 = false;

	get personalValid(): boolean {
		return (
			(this.$v.account.$anyDirty &&
				!this.$v.account.contact?.salutation.$invalid &&
				!this.$v.account.contact?.firstName.$invalid &&
				!this.$v.account.contact?.lastName.$invalid) ||
			false
		);
	}

	get contactValid(): boolean {
		return (
			(this.$v.account.$anyDirty &&
				!this.$v.account.contact?.phone.$invalid &&
				!this.$v.account.contact?.phone2.$invalid) ||
			false
		);
	}

	get settingsValid(): boolean {
		return (
			(this.$v.account.$anyDirty &&
				!this.$v.account.language?.$invalid &&
				!this.$v.account.timezone?.$invalid) ||
			false
		);
	}

	get permissions(): PermissionName[] {
		return this.account.permissions.map((p) => (p as Permission)?.name || p);
	}

	@Watch('account.settingsObject.wizard')
	wizardChanged(newValue: boolean, oldValue: boolean): void {
		if (oldValue !== undefined) {
			this.updateMyAccount();
		}
	}

	@Watch('account.settingsObject.doi')
	doiChanged(newValue: boolean, oldValue: boolean): void {
		if (oldValue !== undefined) {
			this.updateMyAccount();
		}
	}

	@Watch('account.bookingPreset.dialOutCallRetryCount')
	retryCountChanged(newValue: number, oldValue: number): void {
		if (oldValue !== 0) this.updateMyAccount();
	}

	@Watch('account.bookingPreset.dialOutCallRetryDelay')
	retryDelayChanged(newValue: number, oldValue: number): void {
		if (oldValue !== 0) this.updateMyAccount();
	}

	@Validations()
	validations = {
		account: {
			contact: {
				salutation: { required },
				firstName: { required },
				lastName: { required },
				phone: {
					required,
					noLetters: ContactsService.noLettersValidator,
					format: this.phoneFormatValidator
				},
				phone2: {
					noLetters: ContactsService.noLettersValidator,
					format: this.phoneFormatValidator2
				},
				email: { email: validateEmail },
				street: {},
				zip: {},
				city: {},
				country: {},
				title: {},
				company: {}
			},
			language: { required },
			timezone: { required },
			costCenter: {},
			bookingPreset: {
				dialOutCallRetryCount: { required },
				dialOutCallRetryDelay: { required }
			}
		}
	};

	mounted(): void {
		this.getAccount();
		this.setTimezones();
	}

	getAccount(): void {
		AuthenticationService.fetchMyAccount()
			.then((account) => {
				this.setAccount(account);
			})
			.catch((err) => this.toast(err.message, 'danger'));
	}

	save(type?: string): void {
		if (type && type === 'phone') {
			this.$v.account.contact?.phone.$touch();
			this.$v.account.contact?.phone2.$touch();
			setTimeout(() => {
				if (this.loadingPhone1 || this.loadingPhone2) {
					setTimeout(() => {
						this.save('phone');
					}, 500);
				} else {
					if (
						!this.$v.account.contact?.phone.$invalid &&
						!this.$v.account.contact?.phone2.$invalid
					) {
						this.editActive = false;
						(this.$refs['contactSection'] as ProfileSection).close();
						this.updateMyAccount();
					}
				}
			});
		} else {
			this.editActive = false;
			this.updateMyAccount();
		}
	}

	private updateMyAccount() {
		if (this.account.settingsObject) {
			this.account.settings = JSON.stringify(this.account.settingsObject);
		}
		if (this.account.permissions) {
			this.account.permissions = this.permissions;
		}
		if (!this.baseLoading) {
			this.baseLoading = true;
			setTimeout(() => {
				AuthenticationService.updateMyAccount(this.account)
					.then((account) => {
						this.setAccount(account);
						this.toast(this.t('profile.profileUpdated'), 'success');
						this.$v.$reset();
					})
					.catch((err) => {
						this.showNetworkError(err);
						this.$v.$reset();
					})
					.finally(() => (this.baseLoading = false));
			}, 10);
		}
	}

	cancel(): void {
		this.editActive = false;
		this.$v.account.$reset();
		this.getAccount();
	}

	private setAccount(account: Account): void {
		if (account.settings && account.settings !== this.account.settings) {
			this.account.settingsObject = JSON.parse(account.settings);
		}
		if (!account.settings) {
			this.account.settingsObject = { wizard: true, doi: false };
		}
		if (
			this.account.settingsObject &&
			this.account.settingsObject.doi === undefined
		) {
			this.account.settingsObject.doi = false;
		}
		if (account.customerShortDTO) {
			this.account.customerId = account.customerShortDTO?.id;
		}
		if (!account.bookingPreset) {
			account.bookingPreset = {
				dialOutCallRetryCount: 4,
				dialOutCallRetryDelay: 30
			};
		} else {
			if (!account.bookingPreset.dialOutCallRetryCount) {
				account.bookingPreset.dialOutCallRetryCount = 4;
			}
			if (!account.bookingPreset.dialOutCallRetryDelay) {
				account.bookingPreset.dialOutCallRetryDelay = 30;
			}
		}
		this.account = {
			...this.account,
			...account
		};
		const salutation = this.salutations.find(
			(s) => s.value === this.account.contact.salutation
		)?.text;
		this.personal = {
			salutation: salutation || '',
			title: this.account.contact.title || '',
			firstname: this.account.contact.firstName || '',
			lastname: this.account.contact.lastName || '',
			company: this.account.contact.company || '',
			'': '',
			streetAndNumber: this.account.contact?.street || '',
			zipCode: this.account.contact?.zip || '',
			city: this.account.contact?.city || '',
			country: AccountsService.getCountry(
				this.account.contact.country,
				this.user.language
			)
		};
		this.contact = {
			phone: this.account.contact.phone,
			phoneMobile: this.account.contact.phone2 || '',
			email: this.account.contact.email || ''
		};
		this.settings = {
			language: this.account.language,
			timezone: this.account.timezone,
			costCenterPreset: this.account.costCenter?.split('|')[0] || ''
		};
	}

	setTimezones(): void {
		const tzList: { [key: string]: string } = {
			'Etc/GMT+12': '(GMT-12:00) International Date Line West',
			'Pacific/Midway': '(GMT-11:00) Midway Island, Samoa',
			'Pacific/Honolulu': '(GMT-10:00) Hawaii',
			'US/Alaska': '(GMT-09:00) Alaska',
			'America/Los_Angeles': '(GMT-08:00) Pacific Time (US & Canada)',
			'US/Arizona': '(GMT-07:00) Arizona',
			'America/Managua': '(GMT-06:00) Central America',
			'US/Central': '(GMT-06:00) Central Time (US & Canada)',
			'America/Bogota': '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
			'US/Eastern': '(GMT-05:00) Eastern Time (US & Canada)',
			'Canada/Atlantic': '(GMT-04:00) Atlantic Time (Canada)',
			'America/Argentina/Buenos_Aires': '(GMT-03:00) Buenos Aires, Georgetown',
			'America/Noronha': '(GMT-02:00) Mid-Atlantic',
			'Atlantic/Azores': '(GMT-01:00) Azores',
			'Etc/Greenwich':
				'(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
			'Europe/Berlin':
				'(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
			'Europe/Helsinki':
				'(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
			'Europe/Moscow': '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
			'Asia/Tehran': '(GMT+03:30) Tehran',
			'Asia/Yerevan': '(GMT+04:00) Yerevan',
			'Asia/Kabul': '(GMT+04:30) Kabul',
			'Asia/Yekaterinburg': '(GMT+05:00) Yekaterinburg',
			'Asia/Karachi': '(GMT+05:00) Islamabad, Karachi, Tashkent',
			'Asia/Calcutta': '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
			'Asia/Katmandu': '(GMT+05:45) Kathmandu',
			'Asia/Dhaka': '(GMT+06:00) Astana, Dhaka',
			'Asia/Rangoon': '(GMT+06:30) Yangon (Rangoon)',
			'Asia/Bangkok': '(GMT+07:00) Bangkok, Hanoi, Jakarta',
			'Asia/Hong_Kong': '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
			'Asia/Seoul': '(GMT+09:00) Seoul',
			'Australia/Adelaide': '(GMT+09:30) Adelaide',
			'Australia/Canberra': '(GMT+10:00) Canberra, Melbourne, Sydney',
			'Asia/Magadan': '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
			'Pacific/Auckland': '(GMT+12:00) Auckland, Wellington',
			'Pacific/Tongatapu': "(GMT+13:00) Nuku'alofa"
		};

		this.timezones = Object.keys(tzList).map((key: string) => ({
			value: key,
			text: tzList[key]
		}));
	}

	validateState(name: string, isContact?: boolean): boolean | null {
		const validate: any =
			isContact && this.$v.account.contact
				? this.$v.account.contact[name]
				: this.$v.account[name];
		return validate.$dirty && validate.$error ? false : null;
	}

	// TODO replace with generic validator
	phoneFormatValidator(): boolean | Promise<boolean> {
		const telephone = this.$v.account.contact?.phone;
		if (telephone?.$dirty && this.account) {
			this.loadingPhone1 = true;
			console.log('validating');
			return ContactsService.validatePhoneNumbers([this.account.contact.phone])
				.then((validatedNumbers) => {
					const validNumber = validatedNumbers[0];
					const phoneIsValid = validNumber.validNumber;
					if (phoneIsValid) {
						this.account.contact.phone = validNumber.international;
					}
					this.loadingPhone1 = false;
					return phoneIsValid;
				})
				.catch((err) => {
					this.loadingPhone1 = false;
					this.showNetworkError(err);
					return false;
				});
		}
		return false;
	}

	phoneFormatValidator2(): boolean | Promise<boolean> {
		const telephone = this.$v.account.contact?.phone2;
		if (telephone?.$dirty && this.account.contact.phone2) {
			this.loadingPhone2 = true;
			return ContactsService.validatePhoneNumbers([this.account.contact.phone2])
				.then((validatedNumbers) => {
					const validNumber = validatedNumbers[0];
					const phoneIsValid = validNumber.validNumber;
					if (phoneIsValid) {
						this.account.contact.phone2 = validNumber.international;
					}
					this.loadingPhone2 = false;
					return phoneIsValid;
				})
				.catch((err) => {
					this.loadingPhone2 = false;
					this.showNetworkError(err);
					return false;
				});
		}
		return true;
	}
}
