














































import Base from "@/views/Base";
import {Component, Prop} from "vue-property-decorator";
import {DownloadListItem} from "@/models/Presentation";
import axios from 'axios';

@Component
export default class ElementCard extends Base {
  @Prop({type: Number, required: true}) columns!: number;
  @Prop({required: true}) item!: DownloadListItem;

  get thumbnailUrl() {
    if(this.item.hasThumbnail){
      return axios.defaults.baseURL + '/presentation/' + this.item.presentationId + '/download/' + this.item.uuid + '/thumbnail';
    } else if(this.item.thumbnailUrl){
      return this.item.thumbnailUrl;
    } else {
      return "https://via.placeholder.com/300";
    }
  }
}
