










































import {Component, Watch} from 'vue-property-decorator';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import Base from '@/views/Base';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import {SpeakerCardModel} from '@/models/SpeakerCard';
import SpeakerCardService from '@/service/SpeakerCardService';
import SpeakerCard from '@/views/presentation/speakerProfiles/SpeakerCard.vue';
import {debounce} from 'lodash';

@Component({
  components: { SpeakerCard, WListOptions, HeaderLayout, WHeaderBtn }
})
export default class Index extends Base {
  options = {
    searchTerm: '',
    sortBy: 'dateCreated',
    sortDirection: 'DESC',
  };

  items: SpeakerCardModel[] = [];

  @Watch('options.searchTerm')
  searchTermChanged() {
    this.debounceLoadCards();
  }

  mounted() {
    this.loadCards();
  }

  debounceLoadCards = debounce(this.loadCards, 500);

  loadCards() {
    SpeakerCardService.getSpeakerCards(this.options.searchTerm, this.options.sortBy, this.options.sortDirection).then(res => {
      this.items = res;
    }).catch(this.showNetworkError);
  }

  getFullPhotoUrl(id: string, filename?: string): string | undefined {
    if (filename == undefined) {
      return undefined;
    }
    return SpeakerCardService.getSpeakerPhotoUrl(id, filename);
  }

  updateCard(id: string) {
    this.$router.push('/presentation/speaker-profile/' + id);
  }

  sort(){
    this.loadCards()
  }

  async deleteCard(id: string) {
    const card = this.items.find(card => card.id == id);
    if (!card) {
      return;
    }
    if (!await this.$bvModal.msgBoxConfirm(
      this.$t('presentation.speakerProfiles.deleteConfirmModal', { name: card.name }) as string,
      {
        okVariant: 'danger',
        okTitle: this.t('presentation.speakerProfiles.deleteConfirmModalOk'),
        cancelTitle: this.t('common.cancel'),
        centered: true,
        title: this.t('presentation.speakerProfiles.deleteConfirmModalTitle')
      }
    )) {
      return;
    }
    await SpeakerCardService.deleteSpeakerCard(id);
    this.toast(this.t('presentation.speakerProfiles.deletedToast'), 'success');
    this.loadCards();
  }

  async duplicateCard(id: string) {
    const card = this.items.find(card => card.id == id);
    if (!card) {
      return;
    }

    const dup = await SpeakerCardService.duplicateSpeakerCard(id);
    await this.$router.push(`/presentation/speaker-profile/${dup.id}`);
  }

}
