





















































































































































































































































































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import Base from '@/views/Base';
import WPagination from '@/components/WPagination.vue';
import {ListOptions} from '@/models/Booking';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import {
  Registration,
  RegistrationDate,
  RegistrationUser,
  RegistrationUserValue,
  RegistrationUserWithData
} from '@/models/Registration';
import RegistrationService from '@/service/RegistrationService';
import {Validations} from 'vuelidate-property-decorators';
import {required, requiredIf} from 'vuelidate/lib/validators';
import {Validation} from 'vuelidate';
import UserModal from './components/UserModal.vue';
import {formatDateCreated, formatDateForFiles, formatNames} from '@/utils/filters';
import {Email} from '@/models/api';
import {quillEditor} from 'vue-quill-editor';
import ContactsService from '@/service/ContactsService';
import GroupsService from '@/service/GroupsService';
import {Contact} from '@/models/Contact';
import {validateEmail, validateVvId} from '@/utils/validators';
import CertificateMailModal from '@/components/modals/CertificateMailModal.vue';
import {FormMail} from '@/models/Survey';
import {saveAs} from 'file-saver';
import ImportModal from '@/views/forms/components/ImportModal.vue';
import {FormCustomField} from "@/models/CustomField";

@Component({
	components: {
		ImportModal,
		WListOptions,
		WPagination,
		UserModal,
		quillEditor,
		CertificateModal: CertificateMailModal
	}
})
export default class RegistrationItemTable extends Base {
	@Prop() regDate!: RegistrationDate;
	@Prop() registration!: Registration;

	sortBy = 'dateCreated';
	sortDesc = false;

	allSelected = false;
	editIds: string[] = [];
	sendingMail = false;
	sendingTestMail = false;

	loadingCertificate = false;
	loadingSendCertificate = false;

	mailEditorOptions = {
		theme: 'snow',
		placeholder: '',
		modules: {
			toolbar: [['link', 'bold', 'italic', 'underline']]
		}
	};

	tableOptions: ListOptions = {
		entryCount: 0,
		searchTerm: ''
	};

	currentPage = 1;
	perPage = 9999;
	totalRows = 0;
	totalDoiRows = 0;

	fields = [
		{
			key: 'selected',
			label: '',
			thStyle: { width: '35px' },
			class: ' va-middle'
		},
		{
			key: 'dateCreated',
			label: this.t('forms.registration'),
			thStyle: { width: '130px' },
			sortable: true,
			class: 'va-middle'
		},
		{
			key: 'isDoi',
			label: this.t('forms.doi'),
			sortable: false,
			thStyle: { width: '40px' }
		},
		{
			key: 'firstname',
			label: this.t('common.firstname'),
			sortable: true,
			sortDirection: 'desc',
			tdClass: 'truncate'
		},
		{
			key: 'lastname',
			label: this.t('common.lastname'),
			sortable: true,
			sortDirection: 'desc',
			tdClass: 'truncate'
		},
		{
			key: 'phone',
			label: this.t('common.phoneNumber'),
			sortable: true,
			tdClass: 'truncate'
		},
		{
			key: 'actions',
			label: this.t('common.actions'),
			sortable: false,
			thStyle: { width: '115px' }
		}
	];

	files: File[] = [];

	users: any[] = [];
	filteredUsers: any[] = [];
	selectedRow: any = null;

	currentUser: RegistrationUser | null = null;
	mail: Email = {
		replyTo: '',
		subject: '',
		text: '',
		attachments: []
	};
	mailTested = false;

	@Validations()
	validations = {
		currentUser: {
			firstname: { required },
			lastname: { required },
			email: { required, email: validateEmail },
			phone: { customRequired: this.customRequiredValidator('phone') },
			salutation: {
				customRequired: this.customRequiredValidator('salutation')
			},
			addressStreet: {
				customRequired: this.customRequiredValidator('address')
			},
			comment: {
				customRequired: this.customRequiredValidator('comment')
			},
			addressCity: { customRequired: this.customRequiredValidator('address') },
			company: { customRequired: this.customRequiredValidator('company') },
			partnerId: { customRequired: this.customRequiredValidator('partnerId') },
			vvId: { customRequired: this.customRequiredValidator('vvId'), validateVvId },
      customValues: {
        $each: {
          value: {
            required: requiredIf((field: RegistrationUserValue) => this.findCustomField(field.fieldId)?.required),
            regex: (value: string, vm: RegistrationUserValue) => {
              if(!value || value == ""){
                  return true;
                }
              const field = this.findCustomField(vm.fieldId)
              if (field && (field.regex && field.regex != "")) {
                const regexPattern = new RegExp("^" + field.regex + "$", field.ignoreCase ? "i" : "");
                return regexPattern.test(value);
              }
              return true;
            },
            length: (value: string, vm: RegistrationUserValue) => {
              const field = this.findCustomField(vm.fieldId);
              if (value && field?.maxLength != undefined && !(field.maxLength <= 0)) {
                return !(value.length > field.maxLength);
              }
              return true;
            }
          }
        }
      },
      customUrlParams: {
        $each: {
          key: {},
          value: {}
        }
      }
		},
		mail: {
			replyTo: { required, email: validateEmail },
			subject: { required },
			text: { required }
		}
	};

	get editMode(): boolean {
		return this.editIds.length > 0;
	}

	get isDoiEnabled(): boolean {
		if (!this.registration || !this.registration.doi) return false;
		return this.registration.doi.type !== 'DISABLED';
	}

	get isDoiConferenceEnabled(): boolean {
		if (!this.registration || !this.registration.doi) return false;
		return this.registration.doi.type === 'CONFERENCE';
	}

	@Watch('currentPage')
	currentPageChanged(): void {
		this.getUsersForDate();
	}

	@Watch('sortBy')
	sortByChanged(): void {
		if (this.sortBy) {
			this.getUsersForDate();
		}
	}

	@Watch('sortDesc')
	sortDescChanged(): void {
		if (this.sortBy) {
			this.getUsersForDate();
		}
	}

	@Watch('tableOptions.searchTerm')
	search(): void {
		this.filteredUsers = this.users;
		if (this.tableOptions.searchTerm !== '') {
			this.filteredUsers = this.filteredUsers.filter(user =>
				`${user.firstname.toLowerCase()} ${user.lastname.toLowerCase()}`.includes(this.tableOptions.searchTerm.toLowerCase())
				|| user.firstname.toLowerCase().includes(this.tableOptions.searchTerm.toLowerCase())
				|| user.lastname.toLowerCase().includes(this.tableOptions.searchTerm.toLowerCase())
				|| (user.phone || '').replaceAll(' ', '').includes(this.tableOptions.searchTerm.toLowerCase())
			);
		}
	}

	@Watch('regDate')
	getUsersForDate(): void {
		if (!this.sortBy) return;
		if (!this.regDate) return;
		if (!this.registration.id) return;
		this.editIds = [];
		RegistrationService.getRegUsersForDateWithData(this.registration.id, this.regDate.id)
			.then(res => {
				this.users = [];
				this.totalRows = 0;
				if (res.length > 0) {
					this.users = res.map(this.userToRow);
					this.totalRows = res.length;
				}
				this.filteredUsers = this.users;
			})
			.catch(this.showNetworkError)
			.finally(() => {
				// This counts the amount of users who opted in to display the countUserDoi on top of the table
				if (this.isDoiEnabled) {
					this.totalDoiRows = this.users.filter(user => !!user.dateDoi).length;
				}
				// This code removes the DOI column from the table if the regForm has no DOI or DOI disabled
				else {
					this.fields = this.fields.filter((field) => field.key !== 'isDoi');
				}
				this.$emit('setValidCert', !!this.users.find(user => user.validCert));
			});
	}

	@Watch('files')
	filesChanged(): void {
		if (this.mailTested) {
			this.mailTested = false;
			if (this.mail.attachments) {
				this.mail.attachments = [];
			}
		}
	}

	mounted(): void {
		this.getUsersForDate();
	}

	private userToRow(user: RegistrationUserWithData, index: number) {
		const date = user.certInfo?.find(info => info.regDateId === this.regDate?.id);
		const participation = user.participants?.map((p) => p.duration).reduce((o, n) => o + n) || 0;
		const minDurationInMin = this.regDate.minDurationForCert || 0;
    if(this.registration.addressRequired){
      user.addressStreet = user.addressStreet || '';
      user.addressCity = user.addressCity || '';
    }
    if (this.registration.companyRequired) user.company = user.company || '';
    if (this.registration.commentRequired) user.comment = user.comment || '';
    if (this.registration.partnerIdRequired) user.partnerId = user.partnerId || '';
    if (this.registration.vvIdRequired) user.vvId = user.vvId || '';
    if (this.registration.salutationRequired) user.salutation = user.salutation || '';

    if (user.values && user.values.length > 0) user.values.forEach(value => value.value = value.value || '');

    return {
			id: user.id + '',
			index: index + 1,
			selected: false,
			firstname: user.firstname,
			lastname: user.lastname,
			phone: user.phone,
			partnerId: user.partnerId,
			dateCreated: user.dateCreated,
			user,
			dateDoi: user.dateDoi,
			date,
			sentForDate: date?.dateCertMailSent || date?.dateCertMailToAllSent,
			participation,
			validCert: participation / 60 >= minDurationInMin,
			upToDate: date?.certFileUpToDate
		};
	}

	editUser(user: RegistrationUser): void {
		// find user
		// open modal
		this.currentUser = { ...user };
    if(!this.currentUser.customValues){
      this.currentUser.customValues = [];
    }
    if(!this.registration.customFields){
      this.registration.customFields = [];
    }
    this.registration.customFields.forEach(field=>{
      if(!this.currentUser?.customValues?.find(i=>i.fieldId === field.id)){
        this.currentUser?.customValues?.push({
          fieldId: field.id,
          value: ""
        })
      }
    });
		this.$bvModal.show('userModal');
	}

	cancelEditUser(): void {
		this.currentUser = null;
		this.$bvModal.hide('userModal');
	}

	submitEditUser(ok: () => void): void {
		if (!this.registration.id) return;
		if (!this.currentUser) return;
		if (!this.currentUser.id) return;
		if (this.$v.currentUser.$invalid) return;

		RegistrationService
			.updateUser(this.registration.id, this.currentUser.id, this.currentUser)
			.then(user => {
				this.toast(this.t('forms.userEdited'), 'success');
				this.users = this.users.map((u, i) => +u.id === user.id ? this.userToRow(user, i) : u);
        this.search();
				ok();
				this.currentUser = null;
				this.$emit('updateRegistration');
				this.$v.currentUser.$reset();
			})
			.catch(this.showNetworkError);
	}

	deleteUser(user: any): void {
		this.$bvModal
			.msgBoxConfirm(
				this.$t('modals.deleteParticipant.description', {
					name: `${user.firstname} ${user.lastname} ` + (user.phone ? `(${user.phone}`: '')
				}) as string,
				{
					okVariant: 'danger',
					okTitle: this.t('modals.deleteParticipant.ok'),
					cancelTitle: 'Abbrechen',
					centered: true,
					title: this.t('modals.deleteParticipant.title')
				}
			)
			.then((res) => {
				if (!res) return;
				if (this.registration.id && this.regDate.id) {
					RegistrationService.deleteSelectedUsersForDate(this.registration.id, +this.regDate.id, [+user.id])
						.then(() => {
							this.toast(this.t('modals.deleteParticipant.deleted'), 'success');
							this.getUsersForDate();
							this.$emit('updateRegistration');
						})
						.catch(this.showNetworkError);
				}
			});
	}

	deleteSelectedIds(): void {
		this.$bvModal
			.msgBoxConfirm(
				this.$t('modals.deleteParticipants.description', {
					count: this.editIds.length === 1 ? 'einen' : this.editIds.length
				}) as string,
				{
					okVariant: 'danger',
					okTitle: this.t('modals.deleteParticipants.ok'),
					cancelTitle: 'Abbrechen',
					centered: true,
					title: this.t('modals.deleteParticipants.title')
				}
			)
			.then(res => {
				if (!res) return;
				if (this.editIds.length > 0 && this.registration && this.registration.id && this.regDate.id) {
					RegistrationService.deleteSelectedUsersForDate(
						this.registration.id,
						+this.regDate.id,
						this.editIds.map((id) => +id)
					)
						.then(() => {
							this.toast(this.t('modals.deleteParticipants.deleted'), 'success');
							this.getUsersForDate();
							this.$emit('updateRegistration');
							this.editIds = [];
						})
						.catch(this.showNetworkError);
				}
			});
	}

	openMailModal(): void {
		this.$bvModal.show('mailModal');
	}

	sendMail(ok: () => void): void {
		if (!this.$v.mail.$invalid) {
			this.sendingMail = true;
			if (this.files.length > 0 && !this.mailTested) {
				Promise.all(this.files.map((file) => RegistrationService.addFile(file)))
					.then((values) => {
						this.mail.attachments = values;
						this.sendEmailToDateUsers(ok);
					})
					.catch((e) => {
						this.showNetworkError(e);
						this.toast(this.t('forms.fileNotUploaded'));
						this.mail.attachments = [];
						this.files = [];
					});
			} else {
				this.sendEmailToDateUsers(ok);
			}
		}
	}

	sendTestMail(): void {
		if (!this.$v.mail.$invalid) {
			this.sendingTestMail = true;
			if (this.files.length > 0 && !this.mailTested) {
				Promise.all(this.files.map((file) => RegistrationService.addFile(file)))
					.then((values) => {
						this.mail.attachments = values;
						this.sendTestEmailToAdmin();
					})
					.catch((e) => {
						this.showNetworkError(e);
						this.toast(this.t('forms.fileNotUploaded'));
						this.mail.attachments = [];
						this.files = [];
					});
			} else {
				this.sendTestEmailToAdmin();
			}
		}
	}

  private findCustomField(fieldId: number): FormCustomField | undefined {
    const field = this.registration.customFields.find(i=> i.id === fieldId);
    if(!field){
      console.log(`Field with id ${fieldId} does not exist, but there is a field with the id`);
    }
    return field;
  }

	private sendEmailToDateUsers(ok: () => void) {
		if (this.registration.id && this.regDate.id) {
			RegistrationService.sendEmailToDateUsers(
				this.registration.id,
				+this.regDate.id,
				this.mail,
				this.editIds
			)
				.then(() => {
					this.toast(this.t('forms.emailSent'), 'success');
					this.resetMail();
					this.sendingMail = false;
					ok();
				})
				.catch(this.showNetworkError);
		}
	}

	resetMail(): void {
		setTimeout(() => {
			this.mail = {
				replyTo: '',
				subject: '',
				text: '',
				attachments: []
			};
			this.files = [];
		}, 333);

		this.selectedRow = null;
		this.$v.mail.$reset();
	}

	private sendTestEmailToAdmin() {
		if (this.registration.id && this.regDate.id) {
			RegistrationService.sendTestEmailToAdmin(
				this.registration.id,
				+this.regDate.id,
				this.mail,
				this.editIds
			)
				.then(() => {
					this.sendingTestMail = false;
					this.toast(this.t('forms.testEmailSent'), 'success');
					this.mailTested = true;
				})
				.catch(this.showNetworkError);
		}
	}

	formatNames = formatNames;

	validateState(objectName: string, name: string): boolean | null {
		const validate = this.$v[objectName][name] as Validation;
		return validate.$dirty ? !validate.$error : null;
	}

	// TODO optimize and add custom fields
	customRequiredValidator(
		name: string
	): (value: string | undefined) => boolean {
		return (value) => {
			const reg: Registration = (() => this.registration)();
			const reqString = (name + 'Required') as keyof Registration;
			const isRequired = reg[reqString] as boolean;
			return reg && isRequired ? value !== '' : true;
		};
	}

	async addToAddressBook(): Promise<void> {
		let startDate = new Date(this.regDate.start).toLocaleString([], {
			dateStyle: 'short',
			timeStyle: 'short'
		});
		const groups = await GroupsService.getGroups();
		const filteredGroups = groups.filter((g) => g.name.includes(startDate));
		if (filteredGroups.length > 0) {
			const splitName =
				filteredGroups[filteredGroups.length - 1].name.split('-');
			if (splitName.length === 1) {
				startDate = startDate + '-1';
			} else {
				let lastNum = +splitName[1] + 1;
				startDate = startDate + '-' + lastNum;
			}
		}
		const newGroup = await GroupsService.addGroup({
			name: startDate,
			idx: groups.length
		}).catch(this.showNetworkError);
		if (newGroup) {
			const users = this.users
				// if form is of type doi conference, unconfirmed users should be filtered out
				.filter(user => this.isDoiConferenceEnabled ? !!user.dateDoi : true)
				.map(user => ({
					name: user.firstname + ' ' + user.lastname,
					telephone: user.phone,
					company: user.user.company || '',
					groupId: newGroup.id,
					email: user.user.email || ''
				})) as Contact[];
			ContactsService.addContacts(users)
				.then(() => this.toast(this.t('forms.participantsAddedToAdressbook'), 'success'))
				.catch(this.showNetworkError);
		}
	}

	openCertificateModal(row: any): void {
		if (!this.registration) return;
		this.mail.subject = `${this.t('forms.certificateAtThe')} ${formatDateCreated(this.regDate.start, this)}`;
		this.selectedRow = row;
		this.$bvModal.show('certificate-modal');
	}

	downloadSingleCertificate(row: any): void {
		if (!this.registration.id) return;
		this.loadingCertificate = true;
		RegistrationService.downloadCertificate(this.registration.id, row.user.id, this.regDate.id)
			.then(res => {
				let filename = `Zertifikat_${formatDateForFiles(row.user.dateCreated)}_${row.user.lastname}.pdf`;
				saveAs(res, filename);
			})
			.catch(e => this.showNetworkError(e))
			.finally(() => this.loadingCertificate = false);
	}

	async sendCertificate(res: {
		ok: () => void;
		mail: FormMail;
	}): Promise<void> {
		if (this.registration.id && this.selectedRow !== null) {
			this.loadingSendCertificate = true;
			try {
				await RegistrationService.sendCertificate(
					this.selectedRow.user.id,
					this.registration.id,
					this.regDate.id,
					res.mail
				);
				this.toast(this.t('forms.certificateSent'), 'success');
				this.selectedRow.sentForDate = true;
				res.ok();
				this.resetMail();
				this.$emit('updateRegistration');
			} catch (error: any) {
				this.showNetworkError(error);
			} finally {
				this.loadingSendCertificate = false;
			}
		}
	}

	// Table
	rowClass(item: any): string {
		let cssClass = '';
		if (item && item.selected) cssClass += ' table-primary ';
		if (this.isDoi() && this.registration.doi?.type === 'CONFERENCE') {
			if (item && !item.dateDoi) cssClass += ' text-muted ';
		}
		return cssClass + ' w-table no-select';
	}

	rowClicked(item: any, index: number, event: any): void {
		if (event.target.dataset.key !== 'actions') {
			this.rowSelected(!item.selected, item, event.shiftKey);
		}
	}

	rowSelected(value: boolean, item: any, shiftKey?: boolean): void {
		if (item.selected !== value) item.selected = value;
		if (value) {
			if (item.id) this.editIds.push(item.id);
		} else {
			this.editIds = this.editIds.filter((id) => id !== item.id);
		}
		if (shiftKey && value) {
			this.selectBetween(item, value);
		}
	}

	selectAll(value: boolean): void {
		this.users.forEach((item) => {
			if (item.selected !== value) {
				this.rowSelected(value, item);
			}
		});
		if (!value) this.editIds = [];
	}

	selectBetween(item: any, value: boolean): void {
		const index = this.users.findIndex((row) => item.id === row.id);
		const selectedIndex = this.users.findIndex(
			(row) => row.selected === value && row.id !== item.id
		);
		let startIndex: number, endIndex: number;
		if (index < selectedIndex) {
			startIndex = index;
			endIndex = selectedIndex;
		} else {
			startIndex = selectedIndex === -1 ? 0 : selectedIndex;
			endIndex = index;
		}
		this.users.slice(startIndex, endIndex).forEach((item) => {
			if (item.selected !== value) {
				this.rowSelected(value, item);
			}
		});
	}

	dateCreated(date: number): string {
		return new Date(date).toLocaleString(undefined, {
			day: '2-digit',
			month: '2-digit',
			year: '2-digit',
			hour: '2-digit',
			minute: '2-digit'
		});
	}
}
