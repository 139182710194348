import {helpers} from 'vuelidate/lib/validators';
import PresentationService from "@/service/PresentationService";
import {OEmbedResponse} from "@/models/Presentation";

export const validateVvId = helpers.regex(
	'validateVvid',
	/(^\d{8}-\d{6}-\d{2}$|^$)/
);
export const contains = (param: any) => (value: any) => {
	if (!helpers.req(value)) return true;
	return value.indexOf(param) >= 0;
};
export const validateEmail = helpers.regex(
	'validateEmail',
	// eslint-disable-next-line no-control-regex
	/^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9äöüß](?:[a-z0-9äöüß-]*[a-z0-9äöüß])?\.)+[a-z0-9äöüß]{2,}(?:[a-z0-9äöüß-]*[a-z0-9äöüß])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9äöüß-]*[a-z0-9äöüß]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/i
);
export const validateColor = helpers.regex(
	'validateColor',
	// eslint-disable-next-line no-control-regex
	/^#[A-Z]{6}$/
);
export const validateNoSpecialChars = helpers.regex(
	'validateNoSpecialChars',
	// eslint-disable-next-line no-control-regex
	/^[a-zA-Z0-9_.-]*$/
);

export const pinValidator = (value: string) => {
	return (value.length === 6 && /^\d+$/.test(value)) || value === '';
}

export const validateAndNormalizeVideoUrl = (value: any) => {
	const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/|user\/.*\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:.*?[?&#](?:t|start)=([0-9smh]+))?/;
	const vimeoRegex = /^(?:https?:\/\/)?(?:www\.)?vimeo\.com\/([0-9]+)(?:\/([a-zA-Z0-9]+))?(?:\?(?:[^#]*)?)?(?:#t=(\d+(?:[smh]|\.\d+)?))?/;

	let normalizedUrl = '';

	if (youtubeRegex.test(value)) {
		const match = value.match(youtubeRegex);
		if (!match || !match[1]) return false;

		const videoId = match[1];
		let timestamp = match[2] || '';
		if (timestamp) {
			timestamp = timestamp.replace(/[hms]/g, ':').replace(/:$/, ''); // Normalize timestamps like "1m10s" to "70".
		}

		normalizedUrl = `https://youtube.com/watch?v=${videoId}`;
		if (timestamp) normalizedUrl += `&t=${timestamp}`;
	} else if (vimeoRegex.test(value)) {
		const match = value.match(vimeoRegex);
		if (!match || !match[1]) return false;

		const videoId = match[1];
		const hParam = match[2] || '';
		let timestamp = match[3] || '';
		if (timestamp) {
			timestamp = timestamp
				.replace(/[smh]/g, ':')
				.replace(/:$/, '')
				.split(':')
				.reduce((acc: number, curr: string, idx: number, arr: string[]) => acc + parseFloat(curr) * Math.pow(60, arr.length - 1 - idx), 0); // Normalize timestamps like "1m10s" to seconds.
		}

		normalizedUrl = `https://vimeo.com/${videoId}`;
		if (hParam) normalizedUrl += `/${hParam}`;
		if (timestamp) normalizedUrl += `#t=${timestamp}`;
	} else {
		return false;
	}

	return normalizedUrl;
};

export const validateVideoUrlWithOEmbed = async (value: string) => {
	let oEmbedResponse: OEmbedResponse;

	try {
		if (value.includes('youtube.com') || value.includes('youtu.be')) {
			oEmbedResponse = await PresentationService.getYouTubeOEmbed(value);
		} else if (value.includes('vimeo.com')) {
			oEmbedResponse = await PresentationService.getVimeoOEmbed(value);
		} else {
			return false;
		}
	} catch (error) {
		return false;
	}
	return oEmbedResponse || false;
}

export const presentationUrlValidator = (url = ''): boolean => {
	url = url.replace(/\/+$/, '');
	return !url.endsWith("login");
}
