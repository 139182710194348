




























































































































































































































































import {Component} from 'vue-property-decorator';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import SidebarLayout from '@/layouts/nested/SidebarLayout.vue';
import WSwitch from '@/components/WSwitch.vue';
import AdminSection from './AdminSection.vue';
import {Account, Permission, PermissionName} from '@/models/Account';
import {Validations} from 'vuelidate-property-decorators';
import {required} from 'vuelidate/lib/validators';
import Base from '../Base';
import AccountsService from '@/service/AccountsService';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import WCostCenter from '@/components/WCostCenter.vue';
import WCountrySelect from '@/components/WCountrySelect.vue';
import {Customer} from '@/models/Customer';
import ContactsService from '@/service/ContactsService';
import {validateEmail} from '@/utils/validators';
import {getLanguages} from "@/utils/languages";

/**
 * Some documented component
 *
 * @component
 */
@Component({
	components: {
		HeaderLayout,
		SidebarLayout,
		WSwitch,
		AdminSection,
		WHeaderBtn,
		WCostCenter,
		WCountrySelect
	}
})
export default class AdminsNew extends Base {
	account: Account = {
		role: 'ADMIN',
		timezone: 'Europe/Berlin',
		username: '',
		dateCreated: 0,
		id: 0,
		language: 'DE',
		permissions: [],
		contact: {
			lastName: '',
			email: '',
			firstName: '',
			phone: '',
			city: '',
			country: '',
			street: '',
			zip: '',
			company: '',
			salutation: 'OTHER',
			title: '',
			phone2: ''
		},
		costCenter: '',
		passwordPlain: '',
		customerId: -1,
		active: true,
		dateLastLogin: Date.now(),
		deactivationReason: 'NONE',
		failedLoginCounter: 0,
		participantLimit: -1,
		passwordPolicy: 'CHANGE_BY_FIRST_LOGIN',
		timeLimit: -1,
		bookingPreset: {
			dialOutCallRetryCount: 2,
			dialOutCallRetryDelay: 60
		},
    passwordExpiresIn: null
	};

	languages = getLanguages(this);

	timezones!: { value: string; text: string }[];
	salutations = [
		{ text: this.t('admin.selectSalutation'), value: '', disabled: true },
		{ text: this.t('common.mr'), value: 'MR' },
		{ text: this.t('common.mrs'), value: 'MRS' },
		{ text: this.t('common.diverse'), value: 'OTHER' }
	];

	passwordPolicies = [
		{
			text: this.t('admin.passwordPolicyChangeAfterLogin'),
			value: 'CHANGE_BY_FIRST_LOGIN'
		},
		{ text: this.t('admin.none'), value: 'NONE' }
	];

	info = '';

	permissionNames: PermissionName[] = [];

	customer: Customer | null = null;
	customerPermissionNames: PermissionName[] = [];

	limit = false;
	timeLimitStr = '';

	@Validations()
	validations = {
		account: {
			contact: {
				salutation: {},
				firstName: { required },
				lastName: { required },
				phone: {
					required,
					noLetters: ContactsService.noLettersValidator,
					format: this.phoneFormatValidator('account.contact.phone')
				},
				phone2: {},
				email: { required, email: validateEmail },
				street: {},
				zip: {},
				city: {},
				country: {},
				title: {},
				company: {}
			},
			language: {},
			timezone: {},
			costCenter: {},
			username: { required },
			passwordPlain: { required },
			customerId: {},
			passwordPolicy: {},
			timeLimit: {}
		}
	};

	save(): void {
		this.account.timeLimit = this.limit
			? new Date(this.timeLimitStr).getTime()
			: -1;
		this.$v.account.$touch();
		setTimeout(() => {
			if (!this.$v.account.$anyError) {
				this.addAccount();
			} else {
				this.toast(
					this.t('admin.formContainsErrors'),
					'danger'
				);
			}
		}, 250);
	}

	private addAccount(): void {
		const acc = { ...this.account };
		acc.permissions = this.account.permissions.map(
			(p) => (p as Permission).name
		);
		AccountsService.addAccount(acc)
			.then((account) => {
				if (this.info) {
					AccountsService.updateInfo(account.username, this.info).then(() => {
						this.toast(
							'Benutzer und Beschreibung erfolgreich hinzugefügt!',
							'success'
						);
						this.redirect();
					});
				} else {
					this.toast(this.t('admin.userAndDescriptionCreated'), 'success');
					this.redirect();
				}
			})
			.catch((err) => {
				if (
					err.response.data._embedded.errors[0].message.includes(
						'invalid passwordHash'
					)
				) {
					this.toast(
						this.t('admin.passwordRequirements2'),
						'danger'
					);
				} else if (err.response.data.message.includes('already exists')) {
					this.toast(this.t('admin.usernameExists'), 'danger');
				} else {
					this.showNetworkError(err);
				}
			});
	}

	redirect(): void {
		this.$router.push('/admin/admins');
	}

	togglePermission(permission: PermissionName): void {
		const permissions = this.account.permissions.map(
			(p) => (p as Permission).name
		);
		if (permissions.includes(permission)) {
			this.account.permissions = (
				this.account.permissions as Permission[]
			).filter((p: Permission) => p.name !== permission);
		} else {
			(this.account.permissions as Permission[]).push({
				name: permission,
				additional: ''
			});
		}
	}

	validateState(name: string): boolean | null {
		let validate: any = this.$v.account;
		const nameArr = name.split('.');
		if (nameArr.length === 1) validate = validate[name];
		else nameArr.forEach((n) => (validate = validate[n]));
		return validate.$dirty && validate.$error ? false : null;
	}

	phoneFormatValidator(
		phoneStr: string
	): (value: string) => boolean | Promise<boolean> {
		// return Vuelidate validate function that uses phoneStr
		// phoneStr has an 'object.attribute.attribute' structure
		return (value) => {
			const phoneArr: any[] = phoneStr.split('.');
			let phoneForm: any = this.$v; // form
			phoneArr.forEach((p) => (phoneForm = phoneForm[p]));
			if (!phoneForm.required && value === '') return true;
			if (phoneForm?.$dirty && this.account) {
				// validate
				return ContactsService.validatePhoneNumbers([value])
					.then((validatedNumbers) => {
						const validNumber = validatedNumbers[0];
						const phoneIsValid = validNumber.validNumber;
						if (phoneIsValid) {
							// replace with international
							switch (phoneArr.length) {
								case 2:
									(this.account as any)[phoneArr[1]] =
										validNumber.international;
									break;
								case 3:
									(this.account as any)[phoneArr[1]][phoneArr[2]] =
										validNumber.international;
									break;
							}
						}
						return phoneIsValid;
					})
					.catch(() => false);
			}
			return false;
		};
	}
}
