








































































































































































































































































































































































































































































































































































































































































































































































































































import {Component, Watch} from 'vue-property-decorator';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import SidebarLayout from '@/layouts/nested/SidebarLayout.vue';
import WSwitch from '@/components/WSwitch.vue';
import AdminSection from './AdminSection.vue';
import {PermissionName} from '@/models/Account';
import {Validations} from 'vuelidate-property-decorators';
import {maxValue, required, url} from 'vuelidate/lib/validators';
import ContactsService from '@/service/ContactsService';
import Base from '../Base';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import WInlineEdit from '@/components/WInlineEdit.vue';
import {Customer} from '@/models/Customer';
import CustomersService from '@/service/CustomersService';
import WCountrySelect from '@/components/WCountrySelect.vue';
import AccountsService from '@/service/AccountsService';
import {presentationUrlValidator, validateEmail} from '@/utils/validators';
import {AdminSectionField} from "@/models/AdminForms";
import {productPermList} from "@/utils/permissionLists";

/**
 * Some documented component
 *
 * @component
 */
@Component({
  components: {
    HeaderLayout,
    SidebarLayout,
    WSwitch,
    AdminSection,
    WHeaderBtn,
    WInlineEdit,
    WCountrySelect
  }
})
export default class CustomersItem extends Base {
  customer: Customer = {
    dateCreated: '',
    id: 0,
    title: '',
    customerNumber: 0,
    type: 'BILLING',
    anonymizePhonenumbers: true,
    bankAccount: {
      accountHolder: '',
      iban: '',
      bic: '',
      bankName: ''
    },
    createdBy: '',
    showCustomerContactInAccounts: false,
    contact: {
      phone: '',
      city: '',
      country: '',
      street: '',
      zip: '',
      salutation: 'MR',
      title: '',
      company: '',
      firstName: '',
      lastName: '',
      phone2: '',
      addressSuffix: '',
      email: '',
      fax: ''
    },
    billingContact: {
      phone: '',
      phone2: '',
      email: '',
      company: '',
      careOf: ''
    },
    lindenbaumId: '',
    participantLimit: 0,
    permissions: [],
    userLimit: 0,
    invoicePayment: false,
    monthsPasswordExpiration: null
  };

  // TODO: Remove this map, when PRESENTATION permission should be changable on prod
  // productPermList = productPermList.map(perm => ({
  //   ...perm,
  //   disabled: perm.key === 'PRESENTATION' ? this.dev : perm.disabled
  // }));

  get productPermList() {
    return productPermList.map(perm => perm.key === 'PRESENTATION' ? { ...perm, disabled: !this.dev } : perm);
  }

  contactPerson: AdminSectionField[] = [];
  invoice: AdminSectionField[] = [];
  bank: AdminSectionField[] = [];
  options: AdminSectionField[] = [];

  salutations = [
    {text: this.t('common.mr'), value: 'MR'},
    {text: this.t('common.mrs'), value: 'MRS'},
    {text: this.t('common.diverse'), value: 'OTHER'}
  ];
  types = [
    {text: this.t('admin.billingCustomer'), value: 'BILLING'},
    {text: this.t('admin.testCustomer'), value: 'TEST'}
  ];
  egn = [
    {text: this.t('admin.numberShorted'), value: true},
    {text: this.t('admin.unshorted'), value: false}
  ];
  pwExpirationOptions = [
    {text: this.t('common.deactivated'), value: null},
    {text: this.$tc('admin.afterMonths', 1), value: 1},
    {text: this.$tc('admin.afterMonths', 2), value: 2},
    {text: this.$tc('admin.afterMonths', 3), value: 3},
    {text: this.$tc('admin.afterMonths', 6), value: 6},
    {text: this.$tc('admin.afterMonths', 12), value: 12},
  ];

  editActive = false;
  loadingPhone1 = false;
  loadingPhone2 = false;

  info = '';
  userActive = false;
  userActiveHover = false;
  backButtonDisabled = false;

  get contactPersonValid(): boolean {
    return (
        (this.$v.customer.contact?.$anyDirty &&
            !this.$v.customer.contact?.$anyError) ||
        false
    );
  }

  get invoiceValid(): boolean {
    return (
        (this.$v.customer.billingContact?.$anyDirty &&
            !this.$v.customer.billingContact?.$anyError) ||
        false
    );
  }

  get bankValid(): boolean {
    return (
        (this.$v.customer.bankAccount?.$anyDirty &&
            !this.$v.customer.bankAccount?.$anyError) ||
        false
    );
  }

  get optionsValid(): boolean {
    return (this.$v.customer.$anyDirty && !this.$v.customer.$anyError) || false;
  }

  get userLimitRadio() {
    return this.userLimit === -1 ? -1 : 0;
  }

  set userLimitRadio(value: number) {
    this.userLimit = value === -1 ? -1 : 0;
  }

  get participantLimitRadio() {
    return this.participantLimit === -1 ? -1 : 0;
  }

  set participantLimitRadio(value: number) {
    this.participantLimit = value === -1 ? -1 : 0;
  }

  permissionNames: PermissionName[] = [];
  asContact = false;

  participantLimit = -1;
  userLimit = -1;

  @Watch('asContact', {immediate: false})
  asContactChanged(newVal: boolean, oldVal?: boolean): void {
    if (newVal && oldVal !== undefined && this.$v.asContact.$dirty) {
      this.customer.billingContact = {
        ...this.customer.billingContact,
        ...this.customer.contact,
        company: this.customer.title
      };
      this.save();
    }
  }

  @Watch('customer.invoicePayment', {immediate: false})
  paymentChanged(): void {
    if (this.$v.customer.invoicePayment?.$dirty) {
      this.save();
    }
  }

  @Validations()
  validations = {
    asContact: {},
    customer: {
      contact: {
        salutation: {required},
        firstName: {required},
        lastName: {required},
        phone: {
          required,
          noLetters: ContactsService.noLettersValidator,
          format: this.phoneFormatValidator('customer.contact.phone')
        },
        phone2: {
          noLetters: ContactsService.noLettersValidator,
          format: this.phoneFormatValidator('customer.contact.phone2')
        },
        email: {required, email: validateEmail},
        street: {},
        zip: {},
        city: {},
        country: {},
        title: {},
        company: {}
      },
      billingContact: {
        salutation: {required},
        firstName: {},
        lastName: {},
        phone: {
          noLetters: ContactsService.noLettersValidator,
          format: this.phoneFormatValidator('customer.billingContact.phone'),
          required
        },
        phone2: {
          noLetters: ContactsService.noLettersValidator,
          format: this.phoneFormatValidator('customer.billingContact.phone2')
        },
        email: {required, email: validateEmail},
        street: {},
        zip: {},
        city: {},
        country: {},
        title: {},
        company: {},
        careOf: {}
      },
      id: {},
      title: {required},
      customerNumber: {},
      participantLimit: {maxValue: maxValue(4000)},
      permissions: {},
      userLimit: {maxValue: maxValue(4000)},
      type: {required},
      invoicePayment: {},
      bankAccount: {
        accountHolder: {},
        iban: {
          iban: this.ibanValidator
        },
        bic: {},
        bankName: {}
      },
      anonymizePhonenumbers: {},
      showCustomerContactInAccounts: {},
      dataConferenceURL: {
        login: presentationUrlValidator,
        url: url
      },
      monthsPasswordExpiration: {}
    }
  };

  mounted(): void {
    this.baseLoading = true;
    this.getCustomer();
  }

  getCustomer(): void {
    const {id} = this.$route.params;
    if (id) {
      CustomersService.getCustomer(id)
          .then((customer) => {
            this.setCustomer(customer);
            setTimeout(() => {
              this.baseLoading = false;
            });
            if (this.isSAdmin() || this.isAdmin()) {
              CustomersService.getInfo(customer.id).then(
                  (info) => (this.info = info)
              );
            }
          })
          .catch(this.showNetworkError);
    }
  }

  setUserLimit(limit: number): void {
    this.customer.userLimit = this.userLimit === -1 ? -1 : +limit;
    this.$v.customer.userLimit?.$touch();
  }

  setParticipantLimit(limit: number): void {
    this.customer.participantLimit = this.participantLimit === -1 ? -1 : +limit;
    this.$v.customer.participantLimit?.$touch();
  }

  setDataConferenceURL(url: string): void {
    this.customer.dataConferenceURL = url ? url : undefined;
    this.$v.customer.dataConferenceURL?.$touch();
  }

  submitAfterTouch(submitFtn: () => void, path: string): void {
    this.$v.customer[path]?.$touch();
    setTimeout(() => {
      if (
          !this.$v.customer[path]?.$anyError &&
          this.$v.customer[path]?.$anyDirty
      )
        submitFtn();
    }, 250);
  }

  @Watch('customer.showCustomerContactInAccounts')
  saveOnShowCustomer(): void {
    this.save();
  }

  save(type?: string): void {
    if (!this.baseLoading) {
      if (type && type === 'billing') this.asContact = false;
      this.editActive = false;
      this.updateCustomer();
    }
  }

  togglePermission(permission: PermissionName): void {
    if (this.customer.permissions) {
      const permissions = this.customer.permissions.map((p) => p.name);
      if (permissions.includes(permission)) {
        this.customer.permissions = this.customer.permissions.filter(
            (p) => p.name !== permission
        );
      } else {
        this.customer.permissions.push({name: permission, additional: ''});
      }
      CustomersService.updatePermissionsAndLimits(
          this.customer.id,
          this.customer
      )
          .then(() => {
            this.toast(this.t('admin.productChanged'), 'success');
            if (this.customer.permissions)
              this.permissionNames = this.customer.permissions?.map(
                  (permission) => permission.name
              );
          })
          .catch(this.showNetworkError);
    }
  }

  saveInfo(): void {
    if (this.info && this.customer.id) {
      CustomersService.updateInfo(this.customer.id + '', this.info)
          .then(() =>
              this.toast(this.t('admin.descriptionChangedSuccessfully'), 'success')
          )
          .catch(this.showNetworkError);
    }
  }

  toggleActive(): void {
    this.userActiveHover = false;
    if (this.customer.active) {
      CustomersService.deactivateCustomer(this.customer.id).then(
          () => (this.customer.active = false)
      );
    } else {
      CustomersService.activateCustomer(this.customer.id).then(
          () => (this.customer.active = true)
      );
    }
  }

  private updateCustomer() {
    this.backButtonDisabled = true;
    CustomersService.updateCustomer(this.customer.id, this.customer)
        .then((customer) => {
          if (
              this.$v.customer.participantLimit?.$dirty ||
              this.$v.customer.userLimit?.$dirty
          ) {
            return CustomersService.updatePermissionsAndLimits(
                this.customer.id,
                this.customer
            ).then((customer2) => {
              this.setCustomer(customer2);
            });
          } else {
            this.setCustomer(customer);
          }
        })
        .then(() => {
          this.toast(this.t('admin.customerChanged'), 'success');
          this.$v.$reset();
        })
        .catch((err) => {
          this.toast(err.message, 'danger');
          this.$v.$reset();
        })
      .finally(() => this.backButtonDisabled = false);
  }

  cancel(): void {
    this.editActive = false;
    this.$v.customer.$reset();
    this.getCustomer();
  }

  private setCustomer(customer: Customer): void {
    this.customer = {
      ...this.customer,
      ...customer
    };

    this.contactPerson = [
      {
        text: this.t('common.salutation') + '*', value: this.salutations.find(
            (s) => s.value === this.customer.contact.salutation
        )?.text || ''
      },
      {text: this.t('common.title'), value: this.customer.contact.title || ''},
      {text: this.t('common.firstname') + '*', value: this.customer.contact.firstName || ''},
      {text: this.t('common.lastname') + '*', value: this.customer.contact.lastName || ''},
      {text: '', value: ''},
      {text: this.t('common.streetAndNumber'), value: this.customer.contact.street || ''},
      {text: this.t('common.zipCode'), value: this.customer.contact.zip || ''},
      {text: this.t('common.city'), value: this.customer.contact.city || ''},
      {
        text: this.t('common.country'), value: AccountsService.getCountry(
            this.customer.contact.country,
            this.user.language
        )
      },
      {text: '', value: ''},
      {text: this.t('common.phone') + '*', value: this.customer.contact.phone || ''},
      {text: this.t('common.fax'), value: this.customer.contact.fax || ''},
      {text: this.t('common.phoneMobile'), value: this.customer.contact.phone2 || ''},
      {text: this.t('common.email') + '*', value: this.customer.contact.email || ''},
    ];

    this.invoice = [
      {text: this.t('common.company'), value: this.customer.billingContact?.company || ''},
      {
        text: this.t('common.salutation') + '*', value: this.salutations.find(
            (s) => s.value === this.customer.billingContact?.salutation
        )?.text || '',
      },
      {text: this.t('common.title'), value: this.customer.billingContact?.title || ''},
      {text: this.t('common.firstname'), value: this.customer.billingContact?.firstName || ''},
      {text: this.t('common.lastname'), value: this.customer.billingContact?.lastName || ''},
      {text: this.t('admin.careOfShort'), value: this.customer.billingContact?.careOf || ''},
      {text: '', value: ''},
      {text: this.t('common.streetAndNumber'), value: this.customer.billingContact?.street || ''},
      {text: this.t('common.zipCode'), value: this.customer.billingContact?.zip || ''},
      {text: this.t('common.city'), value: this.customer.billingContact?.city || ''},
      {
        text: this.t('common.country'), value: AccountsService.getCountry(
            this.customer.contact.country,
            this.user.language
        ),
      },
      {text: '', value: ''},
      {text: this.t('common.phone') + '*', value: this.customer.billingContact?.phone || ''},
      {text: this.t('common.fax'), value: this.customer.billingContact?.fax || ''},
      {text: this.t('common.phoneMobile'), value: this.customer.billingContact?.phone2 || ''},
      {text: this.t('common.email') + '*', value: this.customer.billingContact?.email || ''},
    ];

    this.bank = [
      {text: this.t('admin.accountOwner'), value: this.customer.bankAccount.accountHolder || ''},
      {text: this.t('admin.iban'), value: this.customer.bankAccount.iban || ''},
      {text: this.t('admin.bic'), value: this.customer.bankAccount.bic || ''},
      {text: this.t('admin.instituteAuto'), value: this.customer.bankAccount.bankName || ''},
    ];

    const userLimit = this.customer.userLimit;
    const participantLimit = this.customer.participantLimit;
    this.userLimit = this.customer.userLimit || -1;
    this.participantLimit = this.customer.participantLimit || -1;

    this.options = [
      {
        text: this.t('admin.customerType') + '*',
        value: this.customer.type === 'BILLING' ? this.t('admin.billingCustomer') : this.t('admin.testCustomer')
      },
      {
        text: this.t('admin.egn') + '*', value: this.customer.anonymizePhonenumbers
            ? this.t('admin.numberShorted')
            : 'Keine'
      },
      {
        text: this.t('admin.passwordExpiration') + '*', value: this.customer.monthsPasswordExpiration
          ? this.$tc('admin.afterMonths', this.customer.monthsPasswordExpiration) : this.t('common.deactivated')
      },
      {text: this.t('admin.userLimit'), value: userLimit === -1 ? this.t('common.unlimited') : userLimit + '' || ''},
      {
        text: this.t('admin.participantLimit') + '*',
        value: participantLimit === -1 ? this.t('conference.unlimited') : participantLimit + '' || '',
      },
      {
        text: this.t('admin.weblinkToPresentation'),
        value: this.customer.dataConferenceURL || 'https://www.csnstart.de'
      },

    ];

    if (this.customer.permissions)
      this.permissionNames = this.customer.permissions.map(
          (permission) => permission.name
      );
  }

  validateState(name: string): boolean | null {
    let validate: any = this.$v.customer;
    const nameArr = name.split('.');
    if (nameArr.length === 1) validate = validate[name];
    else nameArr.forEach((n) => (validate = validate[n]));
    return validate.$dirty && validate.$error ? false : null;
  }

  phoneFormatValidator(
      phoneStr: string
  ): (value: string) => boolean | Promise<boolean> {
    // return Vuelidate validate function that uses phoneStr
    // phoneStr has an 'object.attribute.attribute' structure
    return (value) => {
      const phoneArr: any[] = phoneStr.split('.');
      let phoneForm: any = this.$v; // form
      phoneArr.forEach((p) => (phoneForm = phoneForm[p]));
      if (
          (!phoneForm.required && value === '') ||
          (!phoneForm.required && !value)
      )
        return true;
      if (phoneForm?.$dirty && this.customer) {
        // validate
        return ContactsService.validatePhoneNumbers([value])
            .then((validatedNumbers) => {
              const validNumber = validatedNumbers[0];
              const phoneIsValid = validNumber.validNumber;
              if (phoneIsValid) {
                // replace with international
                switch (phoneArr.length) {
                  case 2:
                    (this.customer as any)[phoneArr[1]] =
                        validNumber.international;
                    break;
                  case 3:
                    (this.customer as any)[phoneArr[1]][phoneArr[2]] =
                        validNumber.international;
                    break;
                }
              }
              return phoneIsValid;
            })
            .catch(() => false);
      }
      return false;
    };
  }

  ibanValidator(value: string): boolean | Promise<boolean> {
    const ibanForm = this.$v.customer.bankAccount?.iban;
    if (value === '') {
      this.customer.bankAccount.bic = '';
      this.customer.bankAccount.bankName = '';
      return true;
    }
    if (ibanForm?.$dirty && this.customer) {
      return CustomersService.validateIban(value)
          .then((validation) => {
            if (!validation.valid) {
              this.customer.bankAccount.bic = '';
              this.customer.bankAccount.bankName = '';
              return false;
            }
            this.customer.bankAccount.bic = validation.bic;
            this.customer.bankAccount.bankName = validation.name;
            return true;
          })
          .catch(() => {
            this.customer.bankAccount.bic = '';
            this.customer.bankAccount.bankName = '';
            return false;
          });
    }
    return false;
  }

  dataConferenceURLBlur(url: string) {
    this.customer.dataConferenceURL = this.customer.dataConferenceURL?.replace(/\/+$/, '');
    this.$v.customer.dataConferenceURL?.$touch()
  }

}
