



















































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import AuthenticationService, {PasswordReset} from '@/service/AuthenticationService';
import {Validations} from 'vuelidate-property-decorators';
import {required} from 'vuelidate/lib/validators';
import Base from './Base';

/**
 * Some documented component
 *
 * @component
 */
@Component
export default class LoginPasswordReset extends Base {
	@Prop() username!: string;
	@Prop() modalId!: string;

	form: PasswordReset = {
		username: '',
		oldPasswordPlain: '',
		newPasswordPlain: '',
		newPasswordPlainConfirmation: ''
	};

	showAlert = false;
	showFillForm = false;
	showSamePassword = false;
	showFalseNewPassword = false;
	showFalseOldPassword = false;
	showNewPasswordUsedInPast = false;

	@Validations()
	validations = {
		form: {
			username: { required },
			oldPasswordPlain: { required },
			newPasswordPlain: { required },
			newPasswordPlainConfirmation: { required }
		}
	};

	@Watch('username')
	usernameChanged(): void {
		this.form.username = this.username;
	}

	mounted(): void {
		if (this.username) {
			this.form.username = this.username;
		}
    this.form.newPasswordPlain = '';
    this.form.newPasswordPlainConfirmation = '';
    this.form.oldPasswordPlain = '';
	}

	submitNewPassword(ok: () => void): void {
		if (this.$v.$invalid) {
			this.showFillForm = true;
			return;
		}
		if (this.form.newPasswordPlain !== this.form.newPasswordPlainConfirmation) {
			this.showAlert = true;
			return;
		}
		if (this.form.newPasswordPlain === this.form.oldPasswordPlain) {
			this.showSamePassword = true;
			return;
		}
		AuthenticationService.setNewPassword(this.form)
			.then(() => {
				this.$emit('passwordResetDone');
				ok();
				this.toast(this.t('login.passwordChanged'), 'success');
				this.reset();
			})
			.catch((error) => {
				const msg = this.errorMessage(error);
				if (msg.includes('newPasswordPlain: invalid passwordHash')) {
					this.showFalseNewPassword = true;
				}
				if (error.response.data.message === 'Old password incorrect') {
					this.showFalseOldPassword = true;
				}
        if (msg.includes('New password has already been used in the past')) {
          this.showNewPasswordUsedInPast = true;
        }
			});
	}

	reset(): void {
		this.$v.$reset();
		this.showAlert = false;
		this.showFillForm = false;
		this.showSamePassword = false;
		this.showFalseNewPassword = false;
		this.showFalseOldPassword = false;
	}
}
