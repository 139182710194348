





































































































































import Base from '@/views/Base';
import { Component, Prop } from 'vue-property-decorator';
import WSwitch from '@/components/WSwitch.vue';
import { SelectOption } from '@/models/common';
import SurveyService from '@/service/SurveyService';
import { CreateSurveyElement, ElementListItem, SurveyElement } from '@/models/Presentation';

@Component({
  components: { WSwitch }
})
export default class AddSurveyModal extends Base {
  @Prop({ default: null }) editItem!: SurveyElement | null;

  editingSurvey = false;

  survey: CreateSurveyElement = {
    question: '',
    description: '',
    surveyElementType: 'SINGLE_CHOICE',
    answers: [
      { text: '', correct: true, idx: 0 }
    ],
    chartType: 'PIE_CHART',
    chartNumberType: 'ABSOLUTE'
  };
  surveyType: 'spontaneous' | 'form' = 'spontaneous';

  surveys: SelectOption[] = [{ text: this.$t('conference.noSurveyForm') as string, value: null, disabled: true }];
  selectedSurvey: number | null = null;

  showResultsOptions = [
    { value: 'PIE_CHART', text: this.$t('presentation.survey.pieChartOption') },
    { value: 'BAR_CHART_H', text: this.$t('presentation.survey.barChartHOption') },
    { value: 'BAR_CHART_V', text: this.$t('presentation.survey.barChartVOption') }
  ];

  shown(): void {
    if (this.editItem) {
      this.survey = {
        question: this.editItem.question,
        description: this.editItem.description,
        surveyElementType: this.editItem.surveyElementType,
        answers: this.editItem.answers.map((a, idx) => ({ ...a, idx })),
        chartType: this.editItem.chartType,
        chartNumberType: this.editItem.chartNumberType
      };
      this.editingSurvey = true;
    }
  }

  getSurveys(): void {
    SurveyService.getSurveys(0, 9999, 'dateCreated', 'DESC')
      .then(surveys => {
        if (surveys.empty) return;
        this.surveys.push(...surveys.content.map(survey => ({
          text: survey.label || survey.title,
          value: survey.id
        }) as SelectOption));
      })
      .catch(this.showNetworkError);
  }

  correctChanged(answer: { correct: boolean }): void {
    console.log(JSON.stringify(answer));
    if(answer.correct){
      if (this.survey.surveyElementType === 'SINGLE_CHOICE') {
        this.survey.answers.forEach(a => {
          if (a !== answer) {
            a.correct = false;
          }
        });
      }
    }

  }

  addAnswer() {
    const idx = this.survey.answers.map(a => a.idx).reduce((a, b) => Math.max(a, b), 0) + 1;
    this.survey.answers.push({ idx, text: '', correct: false });
  }

  deleteAnswer(idx: number) {
    this.survey.answers = this.survey.answers.filter(answer => answer.idx !== idx);
  }

  submit(ok: () => void): void {
    if (this.surveyType === 'spontaneous') {
      if(this.editingSurvey){
        this.$emit('edit-spontaneous-survey', this.survey, this.editItem?.id);
      }
      else{
        this.$emit('add-spontaneous-survey', this.survey);
      }
    } else {
      this.$emit('add-survey-form', this.selectedSurvey);
    }
    ok();
  }

  hidden(): void {
    this.$emit('hidden');
    this.reset();
  }

  reset(): void {
    this.surveyType = 'spontaneous';
    this.survey = {
      question: '',
      description: '',
      surveyElementType: 'SINGLE_CHOICE',
      answers: [
        { text: '', correct: true, idx: 0 }
      ],
      chartType: 'PIE_CHART',
      chartNumberType: 'ABSOLUTE'
    };
    this.selectedSurvey = null;
    this.editingSurvey = false;
  }

  get isSubmitDisabled(): boolean {
    return (this.surveyType === 'form' && !this.selectedSurvey)
      || (this.surveyType === 'spontaneous' && (this.survey.answers || []).some(a => !a.text));
  }

}
