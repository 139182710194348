









































































import Base from "@/views/Base";
import {Component, Prop} from "vue-property-decorator";
import {ElementListItem} from "@/models/Presentation";

@Component
export default class ElementCard extends Base {
  @Prop({type: Number, required: true}) columns!: number;
  @Prop({required: true}) item!: ElementListItem;
  @Prop() selectedElement!: ElementListItem | null;

  nameEdit = false;
  preventBlur = false;

  editName(): void {
    this.nameEdit = true;
    this.$nextTick(() => (this.$refs['name-input'] as HTMLInputElement).focus());
  }

  handleBlur(): void {
    if (!this.preventBlur) this.updateName();
    this.preventBlur = false;
  }

  handleKeydown(): void {
    this.preventBlur = true;
    this.updateName();
  }

  updateName(): void {
    this.nameEdit = false;
    this.$emit('update-name');
  }

  get isSelected(): boolean {
    return this.item.id === this.selectedElement?.id;
  }
}
